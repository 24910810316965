
export default {
  name: 'MPChoiceVariationsProduct',
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      current_variations: null,
      disable_link: true,
      active_mouse_down: false,
    }
  },
  computed: {
    allVariations() {
      const variations_list = []
      this.product?.variations?.forEach(item => {
        if (
          item?.custom_fields?.length &&
          item?.custom_fields[0]?.values?.length
        ) {
          variations_list.push({
            slugged_name: item.slugged_name,
            id: item.id,
            value: item?.custom_fields?.[0]?.values[0],
            current: item?.id === this.product.id,
          })
        }
      })
      return (
        variations_list.sort((a, b) =>
          a?.sort_order === null
            ? 1
            : b?.sort_order === null
            ? -1
            : a?.sort_order - b?.sort_order,
        ) || []
      )
    },
  },
  mounted() {
    const slider = this.$refs[`variations-${this.product.id}`]
    const mobileSlider = this.$refs[`mobileVariations-${this.product.id}`]
    let isDown = false
    let startX
    let scrollLeft

    if (!slider) return

    mobileSlider.addEventListener('touchmove', e => e.stopPropagation())
    slider.addEventListener('mousedown', e => {
      e.stopPropagation()
      this.disable_link = false
      this.active_mouse_down = true
      isDown = true
      slider.classList.add('active')
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener('mouseleave', () => {
      isDown = false
      this.active_mouse_down = false
      slider.classList.remove('active')
    })
    slider.addEventListener('mouseup', e => {
      this.active_mouse_down = false
      e.preventDefault()
      isDown = false
      slider.classList.remove('active')
    })
    slider.addEventListener('mousemove', e => {
      this.disable_link = true
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 1 // scroll-fast
      slider.scrollLeft = scrollLeft - walk
    })
  },
  methods: {
    link(item) {
      return this.localePath({
        name: 'product-slug',
        params: {
          slug: `${item?.id}-${item.slugged_name}`,
        },
      })
    },
    goToLink(item) {
      if (this.disable_link) return
      try {
        this.$router.push({
          path: this.localePath({
            name: 'product-slug',
            params: {
              slug: `${item?.id}-${item.slugged_name}`,
            },
          }),
        })
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
  },
}
