
export default {
  name: 'MPLogo',
  props: {
    logo: {
      type: [Array, String],
      default: () => null,
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    outOfStock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productImageCrawler() {
      return this.logo ?? '/images/no-image-found.webp'
    },
    productImage() {
      return this.logo ? this.logo : '/images/no-image-found.webp'
    },
    showOutOfStockTag() {
      return this.outOfStock && this.$route.name.includes('account-favorites')
    },
  },
}
