
import { mapMutations } from 'vuex'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'MPProductLabels',
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    lotteryLabel: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    direction: {
      type: String,
      default: 'column',
    },
    isPremium: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uiStore = useUiStore()
    return {
      uiStore,
    }
  },
  computed: {
    filteredLabels() {
      const filtered = [...this.labels]
        .filter(
          label =>
            label?.rule_type === 'parameters' &&
            (label?.text || label?.[`text_${this.$i18n.locale}`]),
        )
        .sort((a, b) => {
          if (a.position > b.position) {
            return 1
          } else if (a.position < b.position) {
            return -1
          } else if (a.name > b.name) {
            return 1
          } else if (a.name < b.name) {
            return -1
          }
          return 0
        })
      return filtered.length > 3 ? filtered.slice(0, 3) : filtered
    },
  },
  methods: {
    setBadgeBackground(label) {
      const color = label?.color ? label?.color : ''
      return {
        backgroundColor: color,
      }
    },
    openLotteryPopup() {
      this.uiStore.isPopupLottery = true
    },
    labelText(label) {
      return label?.text || label?.[`text_${this.$i18n.locale}`]
    },
  },
}
