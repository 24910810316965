
import { mapWritableState, storeToRefs } from 'pinia'
import { mapGetters } from 'vuex'
import setDataLayer from '@/mixins/setDataLayer'
import { useUiStore } from '@/stores/ui'
import { useMarketStore } from '@/stores/market'
import {
  generateItemListName,
  rtbAnalytics,
  updateCartIndexInLocalStorage,
} from '@/utilities'
import { useCustomer } from '~/stores/customer'
import { useDataLayerStore } from '~/stores/dataLayer'
import { useBloomreachStore } from '@/stores/bloomreach'

export default {
  name: 'AddToCart',
  mixins: [setDataLayer],
  props: {
    addFirstOffer: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: '',
    },
    offer: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      default: '',
    },
    customView: {
      type: String,
      default: '',
    },
    productIndex: {
      type: Number,
      default: null,
    },
    listName: {
      type: String,
      default: null,
    },
    quantityProduct: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const dataLayerStore = useDataLayerStore()
    const marketStore = useMarketStore()
    const bloomReachStore = useBloomreachStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      dataLayerStore,
      marketStore,
      uiStore,
      resolution,
      bloomReachStore,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    ...mapWritableState(useCustomer, ['userProfile']),
    ...mapWritableState(useMarketStore, [
      'product_other_offers',
      'showMobileCartProduct',
    ]),
    ...mapWritableState(useUiStore, [
      'showRateSuccessDialog',
      'rateSuccessDialogData',
      'showBulkPurchasePopup',
      'registrationRequired',
      'weReceivedYourRequest',
    ]),
    isInCart() {
      if (this?.offer?.uuid) {
        return this.marketStore.cartUUIDS?.find(
          item => item.offer_id === this.offer_id,
        )
      } else if (this.location === 'SingleProduct') {
        return this.marketStore.cartUUIDS?.find(
          item =>
            item.id === this.product_id &&
            (item.offer_id === null || item.offer_id === this.default_offer_id),
        )
      } else {
        return this.marketStore.cartUUIDS?.find(
          item => item.id === this.product_id,
        )
      }
    },

    product_id() {
      return this.product?.product_id || this.product?.id
    },
    offer_id() {
      return this?.offer?.uuid || null
    },
    default_offer_id() {
      return this?.product?.default_offer_uuid || null
    },
    partner_id() {
      return (
        this.product?.merchant_uuid ||
        this.product?.merchant?.uuid ||
        this.product?.partner?.id
      )
    },
    allowShowPopup() {
      return this.customView !== 'cartSellProduct'
    },
    hasOnlyOneOffer() {
      if (this.addFirstOffer) {
        return true
      } else {
        return this.product?.offers?.length === 1
      }
    },
    productWithIndex() {
      return {
        ...this.product, // this.product is the prop
        product_index: this.productIndex, // Add the product_index field
      }
    },
    productForBloomReach() {
      if (this.offer) {
        const { retail_price, old_price } = this.offer

        // Create a new object based on obj2 with overridden values
        return {
          ...this.product,
          retail_price,
          old_price,
        }
      }

      return this.product
    },
  },
  methods: {
    showPopup() {
      setTimeout(() => {
        if (this.resolution === 'sm' || this.resolution === 'md') {
          this.uiStore.isPopupCartMobileVisible = true
        } else {
          this.uiStore.isPopupCartVisible = !this.uiStore.isPopupCartVisible
        }
      }, 500)
      this.loading = false
    },
    async timeMerchantOffersPopup(data) {
      if (data) {
        if (data.avail_check) {
          const merchant_uuid = data.merchant_uuid
          await this.marketStore.getPartnersMerchantOffers(merchant_uuid)
        }
      } else if (this.product && this.product.avail_check) {
        const offersAvailCheck = this.product.offers.filter(i => {
          return i.avail_check
        })
        if (offersAvailCheck?.length) {
          const merchant_uuid = offersAvailCheck[0]?.merchant_uuid
          await this.marketStore.getPartnersMerchantOffers(merchant_uuid)
        }
      }
    },
    async addToCartFunc(product) {
      await this.marketStore
        .addProductToCart({
          product: {
            line_items: [product],
          },
        })
        .then(() => {
          this.bloomReachStore.addedProduct = this.productForBloomReach

          this.bloomReachStore.cartUpdate('add', this.source, 'add')
          this.marketStore.addedProduct = product
          const newProductData = {
            product_id: String(product.product_id),
            product_index: String(product.product_index + 1),
            source: this.source,
          }
          // Store in Local Storage
          updateCartIndexInLocalStorage(newProductData, 'add')
          if (this.allowShowPopup) {
            this.showPopup()
          }
        })
        .finally(() => {
          // this._rtb(`_basketadd_${product.product_id}@${this.$i18n.locale}`)
          rtbAnalytics(this.$i18n.locale, {
            eventType: 'basketadd',
            offerId: `${product.product_id}@${this.$i18n.locale}`,
          })
          setTimeout(async () => {
            this.loading = false
            await this.addToCartDataLayer(product) // set from mixin
            this.marketStore.sourceSelectItem = ''
          }, 300)
        })
    },

    async addToCart(data) {
      let { query, choice } = this.dataLayerStore.getSearchData(this.$route)
      if (
        !choice &&
        this.$route?.name?.includes('product-slug') &&
        this.$route?.query?.from_search
      ) {
        choice = this.product?.name
      }
      let listName = generateItemListName({
        route: this.$route,
        list_name: this.listName,
        query,
        choice,
      })
      let listIndex = this.productIndex
      let addSRC = 'cart'
      if (this.listName == 'favorites') {
        listName = this.product?.src_item_list_name || listName
        listIndex = this.product?.src_item_list_index || listIndex
        query = this.product?.src_query
        addSRC = this.listName
      }
      this.dataLayerStore?.clearOptionAddToCart()
      this.dataLayerStore.pushOptionAddToCart('src_item_list_index', listIndex)
      this.dataLayerStore.pushOptionAddToCart('src_item_list_name', listName)
      this.dataLayerStore.pushOptionAddToCart('src_query', query)
      this.dataLayerStore.pushOptionAddToCart('src_choice', choice)
      this.dataLayerStore.pushOptionAddToCart('item_add_src', addSRC)
      this.showMobileCartProduct = this.product
      if (this.product.status === 'unavailable') return
      if (
        this.product.status === 'out_of_stock' &&
        !this.product.preorder_available
      ) {
        if (this.isAuthenticated) {
          const options = {
            request_type: 'availability_request',
            product_id: this.product?.id,
            product_name: this.product?.name,
            customer_phone: this.userProfile?.attributes?.phone?.replace(
              '+',
              '',
            ),
          }
          this.weReceivedYourRequest = true
          await this.marketStore.SEND_NOTIFICATION_REPORT_AVAILABILITY(options)
        } else {
          this.registrationRequired = true
        }
        return
      } else if (this.product.preorder_available) {
        this.loading = true
        if (this.isAuthenticated) {
          const payload = {
            product_id: this.product?.id,
          }
          this.marketStore.setPreOrderProduct(this.product?.name)
          this.marketStore.SEND_PREORDER_PRODUCT(payload).then(() => {
            this.loading = false
          })
        } else {
          this.registrationRequired = true
          this.loading = false
        }
        return
      }
      if (
        this.hasOnlyOneOffer ||
        this.location === 'OtherSellers' ||
        this.isInCart
      ) {
        const product = {
          product_id: this.product_id,
          quantity:
            this.product?.min_qty > 1
              ? this.product?.min_qty
              : this.marketStore.productDiscountQuantity || 1,
          min_qty: this.product?.min_qty,
          default_marketing_name: this.offer
            ? this.offer?.marketing_name
            : this.product?.default_marketing_name,
          seller_marketing_name: this.offer
            ? this.offer?.seller_marketing_name
            : this.product?.default_marketing_name,
          default_merchant_rating: this.offer
            ? this.offer?.partner_rating
            : this.product?.default_merchant_rating,
          default_offer_allow_qty: this.product.default_offer_allow_qty,
          ...(this.offer_id && { offer_id: this.offer_id }),
          retail_price: this.offer?.retail_price || this.product?.retail_price,
          product_index: listIndex,
          src_item_list_name: listName,
          src_item_list_index: listIndex,
          item_add_src: addSRC,
          src_query: query,
          src_choice: choice,
          old_price: this.offer?.old_price || this.product?.old_price,
          manufacturer: this.product?.manufacturer,
          category_id: this.product?.category_id,
          name: this.product?.name,
        }
        this.rateSuccessDialogData = product
        this.marketStore.availablePopupData = product
        this.marketStore.bulkPurchasePopupData = {
          ...product,
          product_info: this.product,
        }
        if (this.isInCart) {
          try {
            this.$router.push(this.localePath('/cart'))
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        } else if (this.productWithIndex.status === 'active') {
          this.bloomReachStore.addedProduct = product
          if (
            (this.offer && this.offer?.avail_check) ||
            (!this.offer && this.productWithIndex?.avail_check)
          ) {
            this.uiStore.availableCountProduct = product
            if (this.isAuthenticated) {
              this.marketStore.availablePopupProduct = this.productWithIndex
              this.marketStore.sourceSelectItem = this.source
              this.uiStore.availablePopupVisible = true
              data && data.avail_check
                ? this.timeMerchantOffersPopup(data)
                : this.timeMerchantOffersPopup()
              this._rtb('_offlinecheck')
              ///
            } else {
              this.marketStore.availablePopupProduct = this.productWithIndex
              this.marketStore.sourceSelectItem = this.source
              this.uiStore.setAvailableRegisterPopup({
                visible: true,
                product,
              })
            }
          } else if (
            this.rateSuccessDialogData?.default_marketing_name?.ext_id !==
              '84ac8a57-fef1-4580-890a-2011812dc0ae' &&
            this.rateSuccessDialogData?.default_merchant_rating < 65 &&
            this.rateSuccessDialogData?.default_merchant_rating !== 0
          ) {
            this.marketStore.rateSuccessDialogProduct = this.productWithIndex
            this.marketStore.sourceSelectItem = this.source
            this.showRateSuccessDialog = true
          } else if (product.min_qty > 1) {
            this.bulkPurchaseMaxQuantity = this.product.qty
            this.showBulkPurchasePopup = true
            this.marketStore.sourceSelectItem = this.source
          } else {
            this.loading = true
            await this.addToCartFunc(product)
          }
        } else if (this.product.status === 'out_of_stock') {
          this.$message.info(this.$t('market.n_a_info'))
        } else {
          this.$message.info(this.$t('market.product_unavailable_for_order'))
        }
      } else {
        this.product_other_offers = this.productWithIndex
        this.marketStore.sourceSelectItem = this.source
        this.uiStore.isPopupOtherOffersVisible = true
      }
      const partners = []
      this.marketStore.cart?.sellers?.forEach(partner => {
        partner.line_items?.forEach(item => {
          if (item.state === 'out_of_stock') {
            partners.push(item)
          }
        })
      })
      const list_id = partners.map(product => {
        return product.product_id
      })
      const options = {
        'q[id_in]': list_id.join(','),
        include_fields:
          'alternative_products,id,status,merchant_uuid,default_marketing_name,marketing_name,offers,offers.retail_price,offers.marketing_name,default_offer_id,offers.id,offers.old_price,offers.uuid,offers.merchant_uuid,offers.avail_check,default_merchant_rating,offers.partner_rating,ratings.questions,offers.ratings.offers.assessment_id,ratings.offers.product_id,offers.qty,offers.show_stock_qty_threshold,offers.installment_enabled,offers.max_installment_months,suppleir_id,default_merchant_uuid,is_bulk_heavy,offers.seller_marketing_name',
      }
      if (options['q[id_in]']) {
        await this.marketStore.getStockOffersProduct(options)
      }
      await this.marketStore.getAlternativeProducts('cart')
    },
  },
}
